var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('v-tabs', {
    attrs: {
      "center-active": "",
      "show-arrows": ""
    },
    model: {
      value: _vm.list,
      callback: function ($$v) {
        _vm.list = $$v;
      },
      expression: "list"
    }
  }, [_c('v-tab', {
    attrs: {
      "exact": "",
      "to": "/shop/brands"
    }
  }, [_vm._v("전체")]), _vm._l(_vm.items, function (value) {
    return _c('v-tab', _vm._b({
      key: value,
      attrs: {
        "id": value,
        "exact": "",
        "to": '/shop/brands?list=' + value
      }
    }, 'v-tab', {
      value
    }, false), [_vm._v(_vm._s(value))]);
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }