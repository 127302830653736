var render = function render(){
  var _vm$category;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "contentsImmersive",
      fn: function () {
        var _vm$brand, _vm$brand$image, _vm$brand2, _vm$brand3;
        return [_c('v-img', {
          attrs: {
            "aspect-ratio": 1920 / 500,
            "src": (_vm$brand = _vm.brand) === null || _vm$brand === void 0 ? void 0 : (_vm$brand$image = _vm$brand.image) === null || _vm$brand$image === void 0 ? void 0 : _vm$brand$image.url
          }
        }, [_c('div', {
          staticClass: "d-flex align-center justify-center h-100 px-20px py-30px",
          staticStyle: {
            "background-color": "rgba(0, 0, 0, 0.3)"
          }
        }, [_c('div', {
          staticClass: "text-center white--text"
        }, [_c('v-chip', {
          staticClass: "font-weight-bold",
          attrs: {
            "small": "",
            "pill": "",
            "color": "grey darken-4"
          }
        }, [_vm._v("브랜드 전시관")]), _c('div', {
          staticClass: "tit tit--lg mt-4px mt-md-8px"
        }, [_vm._v(_vm._s((_vm$brand2 = _vm.brand) === null || _vm$brand2 === void 0 ? void 0 : _vm$brand2.name))]), _c('div', {
          staticClass: "font-size-14 font-size-md-16 mt-6px mt-md-12px"
        }, [_vm._v(_vm._s((_vm$brand3 = _vm.brand) === null || _vm$brand3 === void 0 ? void 0 : _vm$brand3.desc))])], 1)])])];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    staticClass: "row--contents flex-xl-nowrap"
  }, [_vm.$vuetify.breakpoint.xlOnly ? _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-240px"
  }, [_c('product-list-filter', _vm._g(_vm._b({
    staticClass: "d-none d-xl-block",
    attrs: {
      "categoryCode": _vm.$route.query.category
    }
  }, 'product-list-filter', {
    brand: _vm.brand
  }, false), {
    search: _vm.search
  }))], 1)]) : _vm._e(), _c('v-col', [_c('div', {
    staticClass: "mb-20px"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('tit-wrap-subtitle', {
    staticClass: "mb-0",
    attrs: {
      "titAlign": "text-center text-md-left"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(_vm._s(((_vm$category = _vm.category) === null || _vm$category === void 0 ? void 0 : _vm$category.name) || "전체보기"))])], 2)], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v(" 검색결과 "), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.summary.totalCount.format()))]), _vm._v(" 개 ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('product-list-sort-select')], 1), _c('v-col', {
    staticClass: "d-xl-none",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('product-list-filter', {
    attrs: {
      "categoryCode": _vm.$route.query.category
    }
  })], 1)], 1)], 1), _c('section', {
    staticClass: "product-lists"
  }, [_c('div', {
    staticClass: "product-lists-body"
  }, [_c('shop-product-items', _vm._l(_vm.products, function (product, index) {
    return _c('shop-product-item', {
      key: index,
      attrs: {
        "value": product
      }
    });
  }), 1), _c('div', {
    staticClass: "product-lists__pagination"
  }, [_c('pagination-component', {
    attrs: {
      "value": _vm.page,
      "count": _vm.pageCount
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }