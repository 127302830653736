<template>
    <div class="tabs-wrap">
        <v-tabs v-model="list" center-active show-arrows>
            <v-tab exact to="/shop/brands">전체</v-tab>
            <v-tab v-for="value in items" :key="value" v-bind="{ value }" :id="value" exact :to="'/shop/brands?list='+value">{{ value }}</v-tab>
        </v-tabs>
    </div>
</template>

<script>
const items = new Array(26).fill(1).map((_, i) => String.fromCharCode("A".charCodeAt(0) + i));
export default {
    data: () => ({
        list: undefined,
        items,
    }),
    mounted() {
        this.list = this.$route.query.list || null;
    },
    watch: {
        "$route.query.list"(list) {
            this.list = list || null;
        },
    },
    methods: {
        push() {
            const { ...query } = this.$route.query;
            query.list = this.list;
            if (!query.list) delete query.list;
            this.$router.push({ query });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep{
    .v-item-group {
        display: flex;
        width: 100%;
        .v-btn,
        .v-tab {
            width: 100%;
            min-width: auto !important;
            height: 50px !important;
            font-size: 14px !important;
            margin: 0;
            border-top: 1px solid var(--border-color) !important;
            &--active {
                color: var(--v-grey-darken4) !important;
            }
        }
    }
}
</style>
