var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v("브랜드관")]), _c('brand-list-nav'), _c('div', _vm._b({}, 'div', {
    loading: _vm.loading
  }, false), [_c('v-row', {
    staticClass: "my-n10px my-md-n20px"
  }, _vm._l(_vm.items, function (item) {
    var _item$thumb;
    return _c('v-col', {
      key: item._id,
      staticClass: "py-10px py-md-20px",
      attrs: {
        "cols": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c('v-card', [_c('v-card', [_c('div', {
      staticClass: "square-img",
      style: `background-image:url("${item === null || item === void 0 ? void 0 : (_item$thumb = item.thumb) === null || _item$thumb === void 0 ? void 0 : _item$thumb.url}");`
    })]), _c('div', {
      staticClass: "pt-16px pt-md-20px"
    }, [_c('div', {
      staticClass: "txt txt--sm txt--dark text-truncate mb-4px mb-md-8px"
    }, [_vm._v(_vm._s(item.desc))]), _c('div', {
      staticClass: "tit tit--xs"
    }, [_vm._v(_vm._s(item.name))]), _c('div', {
      staticClass: "pt-12px pt-md-16px"
    }, [_c('v-btn', _vm._b({
      staticClass: "v-size--xx-large",
      attrs: {
        "block": "",
        "to": `/shop/brands/${item === null || item === void 0 ? void 0 : item.code}`
      }
    }, 'v-btn', Object.assign({}, _vm.btn_secondary, _vm.$attrs), false), [_vm._v(" 브랜드 페이지로 이동 ")])], 1)])], 1)], 1);
  }), 1), _c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "height": "50vh"
    }
  }, [_c('v-layout', {
    attrs: {
      "fill-height": "",
      "column": "",
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-card-title', [_vm._v(" 데이터를 불러오고 있습니다")])], 1)], 1)], 1), _c('v-fade-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading && !_vm.items.length,
      expression: "!loading && !items.length"
    }],
    attrs: {
      "height": "50vh"
    }
  }, [_c('v-layout', {
    attrs: {
      "fill-height": "",
      "column": "",
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-card-title', [_vm._v(" 데이터가 없습니다")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }